import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

// import KdpBanner from "../components/banner"
import KdpHeader from "../components/headers/headerGeo";
import KdpFooter from "../components/footers/footerv2";
import KdpAriane from "../components/ariane";
import SidebarGeo from "../components/sidebars/sidebarGeo";

import Youtube from "../components/shortcodes/youtube";
import Image from "../components/shortcodes/image";
import Cta from "../components/shortcodes/cta";
import CtaDevis from "../components/shortcodes/CtaDevis";
import CtaIntervention from "../components/shortcodes/CtaIntervention";

import SideForm from "../components/contacts/sidebarForm";

/*
Usage
<Youtube id="" />
<Image src="" alt="" />
<Cta />
*/
const shortcodes = { Youtube, Image, Cta, CtaDevis, CtaIntervention };

const MdxPage = ({
  data: {
    mdx: {
      frontmatter: {
        date,
        slug,
        title,
        title_meta,
        description_meta,
        excerpt,
        thumbnailText,
        thumbnail,
        fil_ariane,
      },
      body,
    },
  },
}) => {
  const moyen = "Formulaire blicko Geo";

  return (
    <div className="bg-white">
      <Helmet>
        <title>{title_meta}</title>
        <meta name="description" content={description_meta} />
      </Helmet>
      <header className="sticky top-0 z-50">
        {/* <KdpBanner /> */}
        <KdpHeader />
        <KdpAriane fil_ariane={fil_ariane} />
      </header>

      <div class="relative max-w-7xl mx-auto h-full lg:flex">
        <main className="">
          <div class="flex flex-1 overflow-hidden">
            <div class="flex flex-1 flex-col">
              <div class="flex flex-1 bg-white overflow-y-auto paragraph">
                <div className="relative py-8 bg-white overflow-hidden">
                  <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                      <h1>
                        <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                          {title}
                        </span>
                      </h1>

                      <figure className="pt-8">
                        <GatsbyImage
                          className="rounded-lg shadow-lg"
                          alt={thumbnailText}
                          image={getImage(thumbnail)}
                        />
                      </figure>

                      <div className="mt-12 flex flex-col flex-grow bg-[#3333e6] rounded-lg text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8 lg:hidden">
                        <h2 className="text-1xl font-extrabold text-white sm:text-2xl">
                          <span className="block">
                            Plomberie sanitaire ou chauffage
                          </span>
                        </h2>
                        <p className="mt-4 text-m leading-6 text-blue-200">
                          Trouvez facilement un plombier de confiance.
                          Réactivité, qualité et efficacité garanties.
                        </p>
                        <a
                          href="#sideform"
                          className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 sm:w-auto"
                        >
                          Demandez un dépannage
                        </a>
                        <p className="mt-4 py-2 text-xl leading-6 text-white lg:hidden">
                          <a href="tel:0428295016">
                            <span className="font-bold">04 28 29 50 16</span>
                          </a>
                        </p>
                      </div>
                    </div>

                    <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                      <MDXProvider components={shortcodes}>
                        <MDXRenderer className="mt-6 prose prose-sky prose-lg text-gray-500 mx-auto">
                          {body}
                        </MDXRenderer>
                      </MDXProvider>
                    </div>

                    <div className="mt-12 flex flex-col flex-grow bg-[#3333e6] rounded-lg text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
                      <h2 className="text-1xl font-extrabold text-white sm:text-2xl">
                        <span className="block">
                          Plomberie sanitaire ou chauffage
                        </span>
                      </h2>
                      <p className="mt-4 text-m leading-6 text-blue-200">
                        Trouvez facilement un plombier de confiance. Réactivité,
                        qualité et efficacité garanties.
                      </p>
                      <a
                        href="#sideform"
                        className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 sm:w-auto"
                      >
                        Demandez un dépannage
                      </a>
                      <p className="mt-4 py-2 text-xl leading-6 text-white lg:hidden">
                        <a href="tel:0428295016">
                          <span className="font-bold">04 28 29 50 16</span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <aside class="self-start top-32 col-span-1 p-4 lg:py-16 lg:w-1/3 lg:order-last">
          <SideForm moyen={moyen} />

          <SidebarGeo />
        </aside>
      </div>

      <footer>
        <KdpFooter />
      </footer>
    </div>
  );
};

export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        date
        slug
        fil_ariane
        title
        title_meta
        description_meta
        excerpt
        thumbnailText
        thumbnail {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
  }
`;

export default MdxPage;

/*

export default function pageTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  let post = data.markdownRemark
  let featuredImgFluid = post.frontmatter.thumbnail.childImageSharp.fluid

  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark

  return (
    <div className="bg-white">
        <Helmet>
          <title>{frontmatter.title_meta}</title>
          <meta name="description" content={frontmatter.description_meta} />
        </Helmet>
        <header className="sticky top-0 z-50">
            <KdpBanner />
            <KdpHeader />
            <KdpAriane fil_ariane={frontmatter.fil_ariane} />
        </header>

        <div class="relative max-w-7xl mx-auto h-full lg:flex">
          
          <aside class="self-start lg:sticky top-32 col-span-1 p-4 lg:py-8 lg:w-1/3 lg:order-last">
            <SidebarGeo />
          </aside>

          <main className="">
            <div class="flex flex-1 overflow-hidden">
              <div class="flex flex-1 flex-col">
                <div class="flex flex-1 bg-white overflow-y-auto paragraph">
                  <div className="relative py-8 bg-white overflow-hidden">
                    <div className="relative px-4 sm:px-6 lg:px-8">
                      <div className="text-lg max-w-prose mx-auto">
                        <h1>
                          <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            {frontmatter.title}
                          </span>
                        </h1>
                        <figure className="pt-8">
                          <Img fluid={featuredImgFluid}
                            className="w-full rounded-lg"
                            alt={frontmatter.thumbnailText}
                          />
                        </figure>
                      </div>

                      <div 
                        className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto"
                        dangerouslySetInnerHTML={{ __html: html }}
                      />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

          

        </div>
        <footer>
          <KdpFooter />
        </footer>
    </div>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        reference
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        title_meta
        description_meta
        excerpt
        fil_ariane
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        thumbnailText
      }
    }
  }
`

*/
